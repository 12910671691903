@import 'theme/variables/index';

.text {
    font-family: $font-family;
    font-weight: $font-weight-normal;
}

.text--h2 {
    font-size: $font-lg;
    font-weight: $font-weight-bold;
    color: $color-purple;
}

.text--h3 {
    font-size: $font-bg;
    font-weight: $font-weight-bold;
    color: $color-grey-90;
}

.text--small {
    font-size: $font-sm;
}

.text--block {
    display: block;
}

.text--label {
    font-size: $font-xs;
}

.text--color-purple-60 { color: $color-purple-60; }
.text--color-purple-90 { color: $color-purple-90; }
.text--color-purple { color: $color-purple; }

.text--color-grey-20 { color: $color-grey-20; }
.text--color-grey-50 { color: $color-grey-50; }
.text--color-grey-70 { color: $color-grey-70; }
.text--color-grey-90 { color: $color-grey-90; }

.text--color-green-30 { color: $color-green-30; }

.text--color-red-30 { color: $color-red-30; }

.text--color-white { color: $color-white; }
.text--color-teal { color: $color-teal; }

.text--color-background { color: $color-background; }
