@import 'theme/variables';

$slider-offset: 120px;

.slider {
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); // could be a sass function tbh..
        cursor: default;
    }

    &__content {
        position: absolute;
        top: $slider-offset;
        left: 0;
        right: 0;
        min-height: calc(100vh - #{$slider-offset});
        padding: $spacing-32;
        background: $color-white;
        border-top-left-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
    }
}
