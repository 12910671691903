@import 'theme/variables';

$card-visualisation-height: 184px;
$card-visualisation-width: 311px;

.card-visualisation {
    height: $card-visualisation-height;
    max-width: $card-visualisation-width;
    position: relative;

    background: $color-purple;
    color: $color-white;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-sm;
    transition: $transition-time;
    overflow: hidden;

    &--link:hover {
        // something pretty to break the boredom

        cursor: pointer;
        transform: translateY(-$spacing-4);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.card-visualisation__shade {
    position: absolute;
    bottom: 0;
    right: 0;
}

.card-visualisation__logo {
    position: absolute;
    top: 25px;
    left: 20px;
}

.card-visualisation__field {
    display: block;
}

.card-visualisation__label {
    color: $color-grey-20;
}

.card-visualisation__cvc {
    position: absolute;
    top: 20px;
    right: 90px;
}

.card-visualisation__label {
    text-align: right;
}

.card-visualisation__expiry {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 40px;
}

.card-visualisation__name {
    position: absolute;
    bottom: 50px;
    left: 20px;
}

.card-visualisation__number {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.card-visualisation--visa {
    background: $color-teal;

    .card-visualisation__number,
    .card-visualisation__label {
        color: $color-grey-90;
    }

    .card-visualisation__shade svg * {
        fill: $color-teal-darker;
    }
}
