@import './variables/spacing';

.spacing-top-4 { margin-top: $spacing-4; }
.spacing-top-8 { margin-top: $spacing-8; }
.spacing-top-16 { margin-top: $spacing-16; }
.spacing-top-24 { margin-top: $spacing-24; }
.spacing-top-32 { margin-top: $spacing-32; }
.spacing-top-40 { margin-top: $spacing-40; }
.spacing-top-48 { margin-top: $spacing-48; }
.spacing-top-56 { margin-top: $spacing-56; }

.padding-4 { padding: $spacing-4; }
.padding-8 { padding: $spacing-8; }
.padding-16 { padding: $spacing-16; }
.padding-24 { padding: $spacing-24; }
.padding-32 { padding: $spacing-32; }
.padding-40 { padding: $spacing-40; }
.padding-48 { padding: $spacing-48; }
.padding-56 { padding: $spacing-56; }

.pull-right { float: right; }

.text-right { text-align: right; }

.push-right { display: flex; justify-content: flex-end;}

.block { display: block; }
