@import 'theme/variables/index';

.button {
    display: block;
    width: 100%;
    border-width: 0;
    font-size: $font-md;
    font-family: $font-family;
    font-weight: $font-weight-bold;
    background: $color-purple-60;
    color: $color-white;
    padding: $spacing-16;
    border-radius: $border-radius-md;
    text-align: center;
    transition: $transition-time;
}

.button--disabled,
.button[disabled] {
    color: $color-white;
    background: $color-grey-20;
    pointer-events: none;
}

.button--muted {
    background: transparent;
    color: $color-background;
    transition: $transition-time;
}

.button--active,
.button:hover {
    cursor: pointer;
    background: $color-green-30;
    color: $color-white;
}

.button--danger.button--active,
.button--danger:hover {
    background: $color-red-30;
}
