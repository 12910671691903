@import 'theme/variables';

.card-form-field {
    position: relative;

    ::-webkit-input-placeholder {
        color: $color-background; // edge
    }

    :-ms-input-placeholder {
        color: $color-background; // IE 10-11
    }

    ::placeholder {
        color: $color-background;
    }

    &--valid {
        .card-form-field__icon-valid {
            opacity: 1;
        }

        .card-form-field__input {
            color: $color-green-30;
            border-color: $color-green-30;
        }
    }

    &--invalid {
        .card-form-field__icon-invalid,
        .card-form-field__error-message {
            opacity: 1;
        }

        .card-form-field__input {
            color: $color-red-30;
            border-color: $color-red-30;
        }
    }

    &__input {
        display: block;
        font-size: $font-md;
        font-family: $font-family;
        font-weight: $font-weight-normal;

        margin: $spacing-4 0;
        padding: $spacing-4 0;
        background: transparent;
        border: 0;
        border-bottom: 1px solid $color-grey-50;
        width: 100%;
        outline: none;
        color: $color-grey-50;
        transition: $transition-time;
    }

    &__label {
        display: block;
        font-size: $font-md;
        font-family: $font-family;
        font-weight: $font-weight-normal;
    }

    &__error-message {
        color: $color-red-30;
        opacity: 0;
        transition: $transition-time;
    }

    &__icon-valid,
    &__icon-invalid {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: $transition-time;
    }
}
