$color-purple-60: #4C00C2;
$color-purple-90: #32007E;
$color-purple: #3B058E;

$color-grey-20: #D3D8E1;
$color-grey-50: #798291;
$color-grey-70: #444E5D;
$color-grey-90: #1A212C;

$color-green-30: #19AC51;

$color-red-30: #FC484C;

$color-white: #FFFFFF;
$color-teal: #07C9C7;
$color-teal-darker: #01C4C3;
$color-black: #000000;

$color-background: #E5E5E5;
