$font-xs: 10px;
$font-sm: 14px;
$font-md: 16px;
$font-bg: 24px;
$font-lg: 30px;

$font-family: 'Circular Pro';

$font-weight-bold: 900;
$font-weight-normal: 400;

@font-face {
    font-family: 'Circular Pro';
    src: url('./../fonts/lineto-circular-pro-book.woff') format('woff'),
         url('./../fonts/lineto-circular-pro-book.woff2') format('woff2'),
         url('./../fonts/lineto-circular-pro-book.ttf') format('ttf');
}
