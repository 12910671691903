@import 'theme/variables';

.icon {
    display: block;
}

.icon svg * {
    transition: $transition-time;
}

.icon svg * {
    fill: $color-grey-90;
}

.icon.icon--danger svg * {
    fill: $color-red-30;
}

.icon.icon--success svg * {
    fill: $color-green-30;
}
