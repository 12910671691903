@import 'theme/variables';
@import 'theme/utilities';

body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-grey-50;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.application {
    width: $viewport-width;
    padding: $spacing-32;
    position: relative;
    background: $color-white;
}
